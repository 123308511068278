.navRight {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    /* grid-template-rows: 1fr 1fr; */
}

.socialMedia {
    justify-self: end;
    margin-top: 30px;
    width: 100px;
}

.in {
    margin-right: 6px;
}

.fb {
    margin-right: 5%;
}

.listNavRight {
    justify-self: end;
    list-style: none;
    text-align: right;
    margin-right: 5%;
}

.listNavRight li {
    margin-top: 2px;
    color: #e46730;
}

@media screen and (max-width:768px) {
    .navRight {
        display: none;
    }
}