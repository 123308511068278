a {
  text-decoration: none;
}

.MainParent {
  margin-top: 20px;
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 2fr auto;
  padding-top: 10px;
  gap: 10px;
}

.UpperMain {
  text-align: center;
  height: 600px;
  background-size: cover;
  background-position: center;
  width: 100vw;
}

.OpeningH {
  width: 200px;
  margin: auto;
  display: block;
  transform: translateY(100%);
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.OpeningExtra {
  width: 30%;
  margin: auto;
  margin-bottom: 30px;
  display: block;
  transform: translateY(100%);
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.infoText {
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #215746;
  color: white;
}
.infoText h1 {
  color: white;
  font-size: x-large;
}

.lowerOnHover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  transition: 0.15s;
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
}

.lowerOnHover:hover {
  transform: scale(1.025);
}

.lowerXtitles {
  background-color: #fbfcfc;
  background-color: rgba(251, 252, 252, 0.8);
  margin-bottom: 20%;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #215746;
}

.jolaOpnun {
  /* background-image: url("../../resources/SSlager.JPG"); */
  margin: auto;
  margin-top: 80px;
  width: 30%;
  border-radius: 55px;
  justify-content: center;
}
.jolaOpnunBG {
  max-width: 100%;
  max-height: 100%;
  opacity: 0.9;
  border-radius: 55px;
}
.jolaOpnunIMG {
  z-index: 100;
  width: 30%;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 768px) {
  .MainParent {
    grid-template-rows: 2fr auto;
    margin-top: 5px;
  }

  .OpeningH {
    margin: auto;
    display: block;
    transform: translateY(100%);
  }
  .OpeningExtra {
    width: 70%;
  }

  .jolaOpnun {
    /* background-image: url("../../resources/opnunartimi-bakgrunnur.jpg"); */
    margin: auto;
    margin-top: 80px;
    width: 70%;
    border-radius: 55px;
    justify-content: center;
  }
  .jolaOpnunBG {
    max-width: 100%;
    max-height: 100%;
    opacity: 0.9;
    border-radius: 55px;
  }
  .jolaOpnunIMG {
    z-index: 100;
    width: 70%;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
  }
}
