.block-wrap{
    display: none;
    grid-template-rows: 1fr 0.3fr;
    align-items: flex-end;
    margin-top: auto;
    justify-self: end;
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translate(15%);
}
.social-container{
    display: flex;
    justify-content: flex-end;
    gap: 5px;


}
.social-container a{
    border-bottom: none;
}

.MobilelistNavRight{
    padding-left: 0px;
    list-style:none;
    text-align: right;
    margin-right: 10px;
}

.MobilelistNavRight li{
    margin-top: 2px;
    color: #e46730;
}

.MobilelistNavRight li a{
    margin-top: 2px;
    color: #e46730;
    font-size: 16px;
}
@media screen and (max-width:768px) {
    .block-wrap{
        display: grid;
    }
    .social-container{
        justify-content: center;
    }

    .MobilelistNavRight{
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }
}