.nav-bar{
    height: 240px;
    display: grid;
    grid-template-rows: 0.16fr 1fr;
    /* width: 200px; */
    /* width: 100%; */
    padding-bottom: 10px;
}
.nav-upper{
    background-color: #ea5b0c;
    height: 40px;
    /* width: 100%; */
}
.nav-lower{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 160px;
}

@media screen and (max-width:768px) {

    .nav-lower{
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width:1800px) {

    .nav-bar{
        height: 260px;
    }
}
@media screen and (min-width:2000px) {

    .nav-bar{
        height: 280px;
    }
}