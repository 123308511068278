
.contactContainer{
    text-align: center;
    margin-bottom: 200px;
}

.contactContainer h1, .contactContainer h2{
    color: #21634f;
}

.contactInfo{
    color: white;
    background-color: #21634f;
    width: 40%;
    margin: 0 auto;
    border-radius: 5px;
    padding: 30px;
    font-size: x-large;
}

.contactFBIN{
    margin-top: 10px;
}

@media screen and (max-width:768px) {
    .contactContainer{
        margin-bottom: 140px;
    }
    .contactInfo{
        width: 80%;
        /* margin: 0 10px; */
    }
}