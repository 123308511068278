.productsContainer {
  height: auto;
}

.productsDescription {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}

.productsDescription h1,
.productsDescription h2 {
  color: #21634f;
}
.productsDescription h3 {
  padding: 50px;
  color: red;
}

.categoriesContainer {
  display: grid;
}

@media screen and (max-width: 768px) {
  .productsCategories {
    grid-template:
      "a"
      "b"
      "c"
      "d";
  }

  #Categorie1 {
    height: 180px;
  }

  #Categorie2 {
    height: 180px;
  }

  #Categorie3 {
    height: 180px;
  }

  #Categorie4 {
    height: 180px;
  }
}
