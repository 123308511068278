
.aboutContainer{
    text-align: center;
}

.aboutContainer h1, .aboutContainer h2{
    color: #21634f;
}

.text{
    margin: 0 auto;
    width: 70%;
    text-align: justify;
    padding-bottom: 20px;
}

.ssOutsideImage{
    height: 600px;
    background-image: url("../../resources/SamasemUti.jpg");
    background-size: cover;
    background-position: center;
    width: 100vw;
}

.aboutMap{
    margin-top: 30px;
    width: 100%;
    height: 70vh;
}
@media screen and (max-width:768px) {
    .aboutContainer h1{
        padding-top: 0px;
    }
    .aboutContainer h2{
        font-size: 16px;
    }
    .text{
        width: 85%;
    }
}
