/* .not-showing {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 0.5s linear;
  }
  .showing {
    visibility: visible;
    transition: visibility 1s, opacity 0.5s linear;
    opacity: 1;
  
  } */
/* geyma fyrir burger menu ef við viljum reyna hafa eh transition^^ */

.navMiddle {
    display: grid;
    grid-template-rows: 1fr 0.1fr;
    height: 200px;
    justify-content: center;
    /* position: relative; */
    /* padding-bottom: 20px; */
    /* border-bottom: black solid; */
}

.logoBig {
    cursor: pointer;
    max-width: 360px;
    max-height: 85%;
    margin: 0 auto;
}

.listNav {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    top: -10px;
    border-bottom: black solid;
    align-items: center;

}

.close {
    display: none;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    font-size: 32px;
    /* font-weight: ; */
    transition: all 0.2s linear;
    cursor: pointer;
}

.listNav a {
    position: relative;
    text-decoration: none;
    color: inherit;
}


.listNav a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #21634f;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.listNav a:hover::before {
    transform: scaleX(1);
}


.burger-menu {
    display: none;
}

.bar {
    display: block;
    width: 35px;
    height: 5px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #215746;
}

.top-border {
    height: 50px;
    background-color: #ea5b0c;
}

.searchbar-middle {
    display: none;
}
/* Mobile navigation backdrop */
.mobile-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha value for transparency */
    z-index: 1; /* Place the backdrop above other content */
    display: none; /* Initially hidden */
}


@media screen and (max-width:768px) {
    .navMiddle {
        grid-template-rows: 1fr 0.5fr;
        border-bottom: none;
        padding-bottom: 5px;
        /* justify-items: center; */
    }

    .logoBig {
        justify-self: center;
    }

    .listNav {
        /* display: none; */
        position: fixed;
        text-align: center;
        width: 55vw;
        right: 0px;
        height: 102vh;
        justify-content: center;
        /* margin-top: 2%; */
        /* background-color: rgb(203, 203, 203); */
        background-color: white;
        /* margin-left: 2000px; */
        z-index: 1;
    }
    .mobile-backdrop {
        display: block; /* Show the backdrop when the mobile menu is active */
    }

    .listNav a {
        color: #215746;
        display: block;
        margin-top: 20px;
        font-size: 28px;
        border-bottom: 0.5px solid #ea5b0c;
    }

    .hamburger {
        display: block;
    }

    .close {

        display: block;
        color: #215746;
        width: 35px;
        height: 35px;
    }
    
    .close .bar:nth-child(1){
        transform: translateY(14px) rotate(45deg);
    }
    .close .bar:nth-child(2){
        transform: translateY(4px) rotate(-45deg);
    }
    

    /* burger animation */
    .burger-menu {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: block;
        /* font-size: 30px; */
        cursor: pointer;
        padding-bottom: 10px;
    }

    .burger-menu.active .bar:nth-child(2) {
        opacity: 0;
    }

    .burger-menu.active .bar:nth-child(1) {
        transform: translateY(9px) rotate(45deg);
    }

    .burger-menu.active .bar:nth-child(3) {
        transform: translateY(-10px) rotate(-45deg);
    }

    .searchbar-middle {
        display: block;
    }


}