
.FooterContainer{
    width: 100%;
    background-color: #21634f;
    height: 200px;
    margin-top: 10px;
    text-align: center;
    color: white;
    
}

h1{
    padding-top: 50px;
    color: white;
}
h2{
    color: white;
}