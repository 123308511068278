.categoriesList{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 268px;
    gap: 10px;
}

@media screen and (max-width:768px) {

    .categoriesList{
        display: grid;
        grid-template-columns: none;
        grid-auto-rows: auto;
    }

}