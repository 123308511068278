.logoLink {
    width: 150px;
}

.logo {
    /* width: 160px;
    height: 160px; */
    max-width: 100%;
    height: 160px;
    margin-left: 20px;
    /* border: solid black; */
}

.nav-left {
    display: grid;
}

.search-bar {
    justify-self: flex-start;
    margin-left: 20px;
    align-self: center;
    margin-top: 0;
    width: 60%;
}


@media screen and (max-width: 1024px) {
    .search-bar {
        margin-left: 5px;
    }
}



@media screen and (max-width:768px) {
    .logoLink {
        display: none;
    }

    .searchbar {
        display: none;
    }

    .search-bar {
        width: 100%;
        margin-left: 0;
    }

}