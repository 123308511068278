h1 {
  color: #21634f;
  grid-column-start: 2;
  justify-self: center;
}

.search-page-container {
  text-align: center;
  height: 100vh;
}

.search-page-content {
  display: grid;
}

.search-page-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: left;
}

.search-page-container h3 {
  color: red;
}
